module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Talenterer  recruiting site","short_name":"itransition","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/icons/icon-512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ca851355899bd13d72c19ef29e88bc35"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TSNQSV9","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"function() {\n        if (document.location) {\n          const {\n            location: { protocol, hostname, pathname, search },\n          } = document;\n\n          window.dataLayer = window.dataLayer || [];\n\n          return {\n            originalLocation: `${protocol}//${hostname}${pathname}${search}`,\n          };\n        }\n      }"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
